<template>
  <li class="resource billing-item">
    <div
      class="container"
      :style="(item && vendorStyle) || {}"
      :class="{ empty: !item }"
      @click.prevent="item ? '' : onClickAddCard()"
    >
      <template v-if="item">
        <div class="label" :style="(item && vendorStyle) || {}">
          {{ $__t(`${parseCardName}`) }}
        </div>
        <div class="value" v-if="item.card_number">
          **** **** **** {{ item.card_number.substring(item.card_number.length - 4) }}
        </div>
      </template>
      <template v-else>
        <div class="primary">
          <img src="/img/icon-add.svg" />
          <span class="text">{{ $__t("결제수단 등록") }}</span>
        </div>
      </template>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      vendor: {
        kookmin: {
          title: "국민카드",
          reg: ["kb", "국민"],
          backgroundColor: "#FCAF17",
          borderColor: "#FFDF92",
        },
        shinhan: {
          title: "신한카드",
          reg: ["shinhan", "신한"],
          backgroundColor: "#0B1966",
          borderColor: "#6F7ECD",
        },
        hyundai: {
          title: "현대카드",
          reg: ["hyundai", "현대"],
          backgroundColor: "#000000",
          borderColor: "#969696",
        },
        lotte: {
          title: "롯데카드",
          reg: ["lotte", "롯데"],
          backgroundColor: "#DA291C",
          borderColor: "#FFAEA8",
        },
        hana: {
          title: "하나카드",
          reg: ["hana", "하나"],
          backgroundColor: "#008C8C",
          borderColor: "#91EBEB",
        },
        samsung: {
          title: "삼성카드",
          reg: ["samsung", "삼성"],
          backgroundColor: "#0C4DA2",
          borderColor: "#B8D3F7",
        },
        bc: {
          title: "BC카드",
          reg: ["bc", "비씨"],
          backgroundColor: "#FA3246",
          borderColor: "#FFBEC5",
        },
        woori: {
          title: "우리카드",
          reg: ["woori", "우리"],
          backgroundColor: "#20C4F4",
          borderColor: "#B0EDFF",
        },
        kakaopay: {
          title: "카카오페이",
          reg: ["kakao", "카카오"],
          backgroundColor: "#FAE100",
          borderColor: "#FAE100",
          color: "#1E1E1E",
        },
      },
    };
  },

  computed: {
    parseCardName() {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(this.item.card_name);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return this.item.card_name;
    },

    vendorStyle() {
      let styleObject = {
        backgroundColor: "#008fff",
        borderColor: "#008fff",
        color: "#fff",
      };

      if (this.item?.card_name) {
        let key = Object.keys(this.vendor).find((o) =>
          this.vendor[o].reg.some(
            (r) => this.item.card_name.toLowerCase().indexOf(r) > -1
          )
        );

        if (key) {
          styleObject.backgroundColor = this.vendor[key]?.backgroundColor;
          styleObject.borderColor = this.vendor[key]?.borderColor;
          styleObject.color = this.vendor[key]?.color;
        }
      }

      return styleObject;
    },
  },

  methods: {
    onClickAddCard() {
      return this.$router.push({ name: "SelectPaymentForAdd" });
    },
  },
};
</script>

<style lang="scss">
.resource.billing-item {
  position: relative;
  width: 100%;
  padding-top: 63%;

  .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #0099ff;
    padding: unit(14) unit(17);
    border: solid 1px #0099ff;
    border-radius: unit(10);

    .label {
      font-weight: 500;
      font-size: unit(20);
      line-height: 1.48;
      color: $color-white;
    }

    .value {
      color: $color-white;
      line-height: normal;
      margin-top: unit(4);
      font-weight: 500;
      font-size: unit(24);
    }

    &.empty {
      border: 1px solid #a9afb3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $color-white;
      opacity: 1;

      & > .primary > * {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: unit(14);
        color: #878d91;
      }
    }
  }

  &.on {
    .container {
      opacity: 1;
    }
  }
}
</style>
