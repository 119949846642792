<template>
  <div class="resource billing-list">
    <ul>
      <li
        v-for="(item, index) in items"
        :key="`billing-${index + 1}`"
        class="billing-item"
        :class="{
          activated: item.is_primary
        }"
      >
        <div class="container">
          <div class="check" @click="updatePrimaryBilling(item.uid)">
            <input type="checkbox" :value="item.is_primary" :checked="item.is_primary" class="check" readonly />
            <i class="fas fa-check"></i>
          </div>

          <div class="text" @click="updatePrimaryBilling(item.uid)">{{ $__t(`${parseCardName(item.card_name)}`) }} {{ item.card_number }}</div>

          <button type="button" class="close" @click="deleteBilling(item.uid)">
            <img src="/img/modal-close@3x.png" />
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BillingList',
  props: {
    items: Array
  },
  methods: {
    parseCardName(card_name) {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(card_name);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return card_name;
    },

    updatePrimaryBilling(uid) {
      this.$store.commit('loading/SET_TRUE');

      return this.$store.dispatch('billings/updatePrimaryCard', { uid }).finally(() => {
        this.$emit('change', { action: 'update' });
        this.$store.commit('loading/SET_FALSE');
      });
    },

    deleteBilling(uid) {
      this.$store.commit('loading/SET_TRUE');

      return setTimeout(() => {
        return this.$store
          .dispatch('billings/deleteCard', { uid })
          .then((res) => {
            if (res.data.status == 'error') {
              return this.$store.commit('alert/ADD_ITEM', {
                message: this.$__t(res.data.error),
                status: 'error'
              });
            }
          })
          .catch((err) =>
            this.$store.commit('alert/ADD_ITEM', {
              message: err,
              status: 'error'
            })
          )
          .finally(() => {
            this.$emit('change', { action: 'delete' });
            this.$store.commit('loading/SET_FALSE');
          });
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.resource.billing-list {
  margin-top: unit(32);

  .billing-item {
    + .billing-item {
      margin-top: unit(12);
    }

    transition: all 0.2s;
    border: 1px solid #ced3d6;
    border-radius: unit(6);
    padding: 0 unit(12);

    .container {
      display: flex;
      align-items: center;
      gap: unit(6);
      height: unit(50);

      .check {
        flex: 0 0 unit(24);
        height: unit(24);

        input[type='checkbox'] {
          background-color: #ced3d6;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: unit(18);
          color: $color-white;
          -webkit-text-stroke: unit(2) #ced3d6;
        }
      }

      .text {
        flex: 1;
        font-size: unit(15);
        min-width: 0;
        text-overflow: ellipsis;
        margin-left: unit(8);
      }

      .close {
        flex: 0 0 unit(24);
        width: unit(24);
        height: unit(24);
      }
    }

    &.activated {
      border-color: #008fff;

      .check {
        input[type='checkbox'] {
          background-color: #008fff;
        }

        i {
          -webkit-text-stroke: unit(2) #008fff;
        }
      }
    }
  }
}
</style>
