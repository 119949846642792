<template>
  <div
    class="page billing-list"
    :class="{ 'more-depth': $route.meta.headerType === 'more-depth' }"
  >
    <div class="container">
      <div class="page-header">
        <div class="page-header__row">
          <div class="page-header__title">{{ $__t("결제수단") }}</div>
          <div class="page-header__link-area">
            <router-link :to="{ name: 'SelectPaymentForAdd' }">
              <span>{{ $__t("추가") }}</span>
            </router-link>
          </div>
        </div>
        <div class="page-header__row">
          <div class="page-header__secondary">
            {{ $__t("{billing}.header.secondary") }}
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="section billing-frame">
          <BillingItem ref="frame" :item="primaryBilling" />
        </div>

        <div class="section billing-list">
          <BillingList :items="billings" @change="onChangeBillingList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingItem from "@/v3/components/resources/billing/BillingItem.vue";
import BillingList from "@/v3/components/resources/billing/BillingList.vue";

export default {
  name: "BillingListAndChangePrimary",

  components: {
    BillingItem,
    BillingList,
  },

  data() {
    return {
      listQuery: {
        page: 1,
        size: 10,
      },

      selectedBilling: null,
    };
  },

  mounted() {
    this.$analytics.logEvent("payment page view");

    this.$store.commit("loading/SET_TRUE");
    this.getPayMethods(true).finally(() => this.$store.commit("loading/SET_FALSE"));

    setTimeout(() => {
      this.onResize();

      window.addEventListener("resize", this.onResize);
      this.toggleHeaderTitleVisibility();
      window.addEventListener("scroll", this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener("scroll", this.toggleHeaderTitleVisibility);
  },

  computed: {
    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    },
  },

  methods: {
    checkShow() {
      const { tester } = this.$store?.state?.auth;
      return tester;
    },

    async getPayMethods(clear) {
      try {
        if (clear) {
          this.$store.commit("billings/CLEAR_ITEMS");
        }

        return await this.$store.dispatch("billings/getCardList", {
          query: this.listQuery,
        });
      } catch (e) {}
    },

    onChangeBillingList({ action }) {
      this.$store.commit("loading/SET_TRUE");
      this.getPayMethods(action === "delete" ? true : false).finally(() =>
        this.$store.commit("loading/SET_FALSE")
      );
    },

    onResize() {
      const FRAME = this.$refs.frame.$el;
      const FRAME_STYLE = getComputedStyle(FRAME);

      const innerHeight =
        FRAME.clientWidth -
        parseFloat(FRAME_STYLE.paddingLeft) -
        parseFloat(FRAME_STYLE.paddingRight);

      FRAME.style.height = innerHeight * 0.59 + "px";
    },

    parseCardName(card_name) {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(card_name);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return card_name;
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $(".page-header .page-header__title", this.$el);
      const $siteHeaderTitle = $("#site-header > .container > .header > .title");
      const $siteHeader = $("#site-header");

      if (clear === true) {
        $siteHeaderTitle.removeClass("off");
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass("off");
        $siteHeader.removeClass("shadow");
      } else {
        $siteHeaderTitle.removeClass("off");
        $siteHeader.addClass("shadow");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page.billing-list {
  .section.billing-frame {
    .billing-item {
    }
  }

  .section.billing-list {
  }
}
</style>
